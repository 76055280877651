.fit-radio-container {
  width:100%;
  border-radius: 8px;
  background-color: rgba(142, 142, 147, 0.1);
  margin-top: 32px;
}
.top-container{
  display: flex;
}

.display-grid{
  display:grid
}

.mainTitle {
  display: grid;
  grid-auto-flow: column;
  border-radius: 8px;
  grid-auto-columns: 1fr 8fr 3fr;
  position:relative
}

.mix-box{
  margin: 0 16px;
  font-family: SFProDisplay-SemiBold;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: -0.15px;
  color: #000;
  display: block;
  text-align: left;
  align-self: center;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.fit-radio-card {
  margin: 32px 16px 24px 0px;
}
.list-title {
  height: 16px;
  margin: 9.3px 3px 0 0;
  font-family: SFProDisplay-SemiBold;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.07;
  letter-spacing: -0.18px;
  display: block;
  color: #000;
  margin-top: 8px;
  text-align: left;
}
.grid-container {
  display: flex;
  gap: 8px;
}
.grid-item{
  position: relative;
  box-sizing: border-box;
}
.track-artist{
  font-family: 'SFProDisplay-Regular';
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: -0.15px;
  color: #000;
}
.track-title{
  font-family: SFProDisplay-SemiBold;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.76;
  letter-spacing: -0.15px;
  color: #000;
}

.fit-radio-card-carousel{
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  width: 92%;
  margin-left: 16px;
  scroll-behavior: smooth;
}

.fit-radio-card-thumb-image {
  width: 152px;
  height: 121.6px;
  border-radius: 8px;
  object-fit: cover;
}

.fit-radio-card-carousel::-webkit-scrollbar {
 display: none ;
}

.Rectangle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 8px 0px 0px 8px;
  background-color: #000;
}

.buttonShiftLeft{
  display: none;
  width: 24px;
  height: 24px;
  margin: 0 0 40px 8px;
}

.buttonShiftRight{
  display: none;
  width: 24px;
  height: 24px;
  margin: 0 0 40px 8px;
}

.audio-player{
  display: none !important;
}

.slider-container{
  margin-left: 16px;
  height: 164px
}

.slider-heading{
  font-size: 13px;
  text-align: left;
  color: #757579;
  font-family: 'SFProDisplay-Regular';
  width: max-content;
}

.cross-icon{
  height:24px;
  width:24px;
  margin: 8px 8px 0 0;
  position: absolute;
  right: 0;
}

.hidden{
  height: 0px !important;
  padding: 0px !important;
  overflow: hidden;
}

.transition-height{
  transition: all 0.2s cubic-bezier(0.4, 0, 1, 1);

}

.fit-radio-card-carousel-container{
  display: flex;
  justify-content: space-between;
  align-items: center
}

.icon-selected{
  width: 24px;
  height: 24px;
  display: inline;
  position: absolute;
  right: 8px;
  top:8px
}

.music-icon{
  z-index: 5;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 50%;
}

.player-icon {
  height: 32px;
  width: 32px;
  justify-self: center;
  align-self: center;
}

.track-info{
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 16px;
}
#toggle-button{
  display: none;
}

@media only screen and (min-width: 768px){
  .fit-radio-container {
      width: 496px;
    }
  .buttonShiftLeft,.buttonShiftRight{
  display: inline;
  }
  .fit-radio-card-carousel{
    margin-left: 0px;
  }
  .hidden-arrows {
      visibility: hidden !important;
  }
  .mainTitle{
    grid-auto-columns: 1fr 8fr 2fr;
  }
  .slider-container {
    margin-left: 32px;
  }
}

@media only screen and (min-width: 1360px){
    .fit-radio-container {
      position: relative;
      width: 798px;
    }
    .mix-box, .track-artist,.track-title, .slider-heading {
      font-size: 17px;
    }
    .list-title{
      font-size: 15px;
    }
    .fit-radio-card-carousel{
      width: 85%
    }
    .fit-radio-card {
    margin: 32px 16px 32px 0px;
    }
    .fit-radio-card-thumb-image {
      width: 187px;
      height: 145.7px;
    }
    .Rectangle {
      width: 96px;
      height: 96px;
    }
    .slider-container{
      margin-left: 64px;
      height: 168px
    }
    .music-icon {
      width: 36px;
      height: 36px;
    }
    .mainTitle {
      grid-auto-columns: 1fr 8fr 1.2fr;
    }  
    .buttonShiftLeft {
      margin: 0 0 40px 24px;
    }  
    .buttonShiftRight {
      margin: 0 24px 40px 0;
    }
    #toggle-button{
          display: block;
          position: absolute;
          bottom: 32px;
          right: 32px;
          width: 112px;
          height: 48px;
          font-family: 'SFProDisplay-Bold';
          font-size: 20px;
    }
  }

@media only screen and (min-width: 2000px) {
  .fit-radio-container {
    width: 880px;
  }
}