.grid-list-view {
  font-family: SFProDisplay-Semibold;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: #757579;
  margin-right: 12px;
  text-transform: uppercase;
}

.line-grid-view {
  height: 39px;
  margin: 2px 8px 0 12px;
  border-left: solid 1px #979797;
}
.list-grid-view-area {
  width: auto !important;
}
.list-grid-view-area {
  display: flex;
  justify-content: end;
  align-items: center;
  padding-top: 24px;
  padding-right: 14px;
}
.list-icon {
  padding-right: 11px !important;
  padding-left: 12px;
}
.browser-collection-list-page {
  margin-top: 6px !important;
}
.browser-collection-grid-page {
  margin-top:16px !important;
}
@media (min-width: 767px) {
  .list-grid-view-area {
    padding-top: 16px;
    padding-right: 36px;
  }
  .browser-collection-grid-page {
    margin-top: 25px !important;
    padding-top: 0px !important;
  }
  .browser-collection-list-page {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
}
@media (min-width: 1359px) {
  .main-margin .browser-collection-grid-page {
    margin-top: 27px !important;
    padding-top: 0px !important;
  }
  .main-margin .browser-collection-list-page {
    padding-top: 0px !important;
    margin-top: 0px !important;
  }
  .line-grid-view {
    height: 39px;
    margin: 7px 8px 0 12px;
    border-left: solid 1px #979797;
  }
  .list-grid-view-area {
    padding-top: 19px;
    padding-right: 93px;
  }

  .grid-list-view {
    font-size: 15px;
    line-height: 1.47;
    padding-top: 6px;
  }
  .icon-svg-list {
    padding-top: 11px !important;
  }
}
@media (min-width: 2000px) {
  .list-grid-view-area {
    position: relative;
    z-index: 999;
  }
  .main-margin .browser-collection-list-page {
    position: relative;
    top: -22px;
  }
  .list-grid-view-area {
    padding-top: 28px;
  }
}
@media (max-width:767px)
{
  .browser-collection-grid-page .card-footer {
    // margin-top: -11px !important;
    // margin-bottom: 16px!important;

  }
  .card-footer-info {
    margin-top: -4px!important;
}
}
