.global-search{
    .text-left{
        text-align: left;
    }
    .page-title{
      font-size: 40px !important;
        @media (max-width: 719px) {
            display: none;
        }
    }
    .heading{
        margin-bottom: 40px;
        @media (max-width: 719px) {
            margin-bottom: 30px;
            margin-top: 1.5em;
        }
    }
    .collection-page-list{
        @media (max-width: 719px) {
            padding-top: 0;
        }
    }
    .result-total-container{
        margin-bottom: 10px;
    }
    .result-total{
        font-family: SFProDisplay-Regular;
        color: #757579;
        font-size: 15px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.47;
        letter-spacing: normal;
    }
    .search-items{
      width: 100%;
      .comp-search{
        @media (max-width: 719px) {
          width: 100%;
      }
          width: 448px;
          height: 42px;
          border-radius: 8px;
          background-color: #efeff4;
          justify-content: space-around;
          align-items: center;
          &.disable{
            opacity: 0.4;
            pointer-events: none;
          }
          .icon-search,.icon-close{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            .MuiSvgIcon-root{
              width: inherit;
              height: inherit;
            }
          }
          .icon-search{
            margin-right: 0;
            flex-basis: 10%;
          }
          .icon-close{
            flex-basis: 8%;
          }
          .text-box{
            flex-basis: 85%;
            .MuiInputBase-root{
              width: 100%;
            }
            input[type='text']{
              font-size: 17px;
              font-weight: normal;
              font-stretch: normal;
              font-style: normal;
              letter-spacing: -0.3px;
              font-family: SFProDisplay-Regular;
            }
          }
          @media (max-width: 719px) {
              display: flex;
          }
        }
        .error-msg{
          position: absolute;
          top: inherit;
        }
    }
    .m-t-searchbar{
      margin-top: 24px;
    }
}

.m-b-search-local-data{
  margin-bottom: 19px;
  @media only screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
  @media only screen and (min-width: 1360px) {
    margin-bottom: 48px;
  }
}
.m-b-search-local{
  @media only screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
  @media only screen and (min-width: 1360px) {
    margin-bottom: 48px;
  }
}