.carousel-item-name {
  font-family: SFProDisplay-Semibold;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: -0.29px;
  color: #fff;
  padding-left: 15px;
  padding-right: 15px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.carousel .carousel-details .carousel-image {
  border-radius: 0px !important;
}

.carousel-item-description {
  font-family: SFProDisplay-Regular;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 20px;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.carousel-item-visit {
  font-family: SFProDisplay-Semibold;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.87;
  letter-spacing: normal;
  color: #fff;
  padding-left: 16px;
  text-align: left;
  padding-top: 5px;
}

.carousel-details {
  position: absolute;
  top: 50px;
  z-index: 999;
}
.carousel-details-profile {
  position: absolute;
  top: 48px;
  z-index: 999;
}
.paper {
  box-shadow: none !important;
}
.carousel,
.css-ohwg9z,
.crousel-image,
.carousel-item-overlay {
  height: 296.4px !important;
  border-radius: 0px !important;
}
.carousel .css-ohwg9z .carousel-image {
  height: 296.4px !important;
}
.carousel-page-shimmer {
  height: 296.4px !important;
  border-radius: 0% !important;
}
.right-arrow-icon {
  position: relative;
  top: 7px;
  cursor: pointer;
}
.indicator-container {
  position: absolute;
  top: 78%;
  font-size: 40px;
  z-index: 999;
}
.indicator-container button svg {
  width: 9px;
  height: 9px;
}
.indicator-container button {
  margin: 0 4px 0 0;
}
.crousel-image:after {
  background-color: rgba(0, 0, 0, 0.7) !important;
  content: "" !important;
  height: 100% !important;
  position: absolute !important;
  width: 100% !important;
  z-index: -1 !important;
}
.carousel-item-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
}

.carousel-profileImg {
  width: 64px;
  height: 64px;
  cursor: pointer;
  background-color: white;
  border-radius: 8px;
  margin-left: 16px;
}
.profile-item-name {
  display: flex;
  align-items: center;
}
@media (min-width: 767px) {
  .carousel-details-profile {
    position: absolute;
    top: 56px;
    z-index: 999;
  }
  .carousel-profileImg {
    width: 48px;
    height: 48px;
  }
  .profile-item-name {
    display: flex;
    align-items: center;
    padding-left: 14px;
  }
  .right-arrow-icon {
    position: relative !important;
    top: 8px !important;
  }
  .carousel-item-name {
    font-family: SFProDisplay-Semibold;
    font-size: 28px;
    line-height: 1.14;
    letter-spacing: -0.2px;
    text-align: left;
  }

  .carousel-item-description {
    font-family: SFProDisplay-Regular;
    font-size: 13px;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    padding-left: 32px;
    padding-top: 7px;
  }

  .carousel-item-visit {
    font-family: SFProDisplay-Semibold;
    font-size: 13px;
    font-weight: 600;
    line-height: 2.15;
    letter-spacing: normal;
    text-align: left;
    padding-left: 32px;
    padding-top: 4px;
    cursor: pointer;
  }
  .carousel,
  .css-ohwg9z,
  .crousel-image,
  .carousel-item-overlay {
    height: 237.9px !important;
  }

  .carousel .css-ohwg9z .carousel-image {
    height: 237.9px !important;
  }
  .carousel-details {
    top: 30px;
  }
  .indicator-container {
    top: 72%;
  }
  .carousel-page-shimmer {
    height: 237.9px !important;
  }
}
@media (min-width: 1359px) {
  .carousel-details-profile {
    position: absolute;
    top: 96px;
    z-index: 999;
    width: 808px;
  }
  .profile-item-name {
    padding-left: 65px;
  }
  .right-arrow-icon {
    position: relative !important;
    top: 7px !important;
  }
  .right-arrow-icon {
    top: 7px !important;
  }
  .carousel-details {
    width: 808px;
    top: 62px;
  }
  .carousel-item-name {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.3;
    letter-spacing: -0.8px;
  }

  .carousel-item-description {
    font-size: 17px;
    line-height: 1.29;
    letter-spacing: normal;
    padding-left: 80px;
    padding-top: 14px;
  }

  .carousel-item-visit {
    font-size: 17px;
    font-weight: 600;
    line-height: 1.65;
    letter-spacing: normal;
    padding-left: 80px;
    padding-top: 12px;
  }

  .carousel,
  .css-ohwg9z,
  .crousel-image,
  .carousel-item-overlay {
    height: 396.3px !important;
  }
  .carousel .css-ohwg9z .carousel-image {
    height: 396.3px !important;
  }
  .indicator-container {
    top: 80%;
  }
  .indicator-container button svg {
    width: 18px;
    height: 18px;
  }
  .indicator-container button {
    margin: 0 2px 0 0;
  }
  .carousel-page-shimmer {
    height: 401.3px !important;
  }
}
@media (min-width: 2000px) {
  .carousel-profileImg {
    margin-right: 16px;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    cursor: pointer;
  }
  .carousel-details {
    width: 907px;
    top: 80px;
  }
  .carousel-item-name {
    font-size: 70px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -0.8px;
  }
  .carousel-details-profile .carousel-item-description {
    width: 907px;
    padding-top: 26px !important;
  }

  .carousel-item-description {
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: normal;
    padding-top: 0px;
  }
  .carousel-details-profile .carousel-item-visit {
    padding-top: 24px !important;
  }
  .carousel-item-visit {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: normal;
    padding-top: 19px;
  }
  .carousel,
  .css-ohwg9z,
  .crousel-image,
  .carousel-item-overlay {
    height: 587.5px !important;
  }
  .carousel .css-ohwg9z .carousel-image {
    height: 587.5px !important;
  }
  .carousel-page-shimmer {
    height: 587.5px !important;
  }
  .indicator-container {
    top: 85.7%;
  }
}
